<div class="sidebar__header" (click)="toggle()">
  <!-- <div *ngIf="!collapsed">SPA Client</div> -->
  <div *ngIf="!collapsed">
    <div style="background-color: white; border-radius: 10px;">
      <img src="../../../assets/images/puprlogo.png" alt="images" style="width: 100%; height: 100%; border-radius: 0px;" />
    </div>
  </div>
  <button type="button" style="background-color: #222f6b; border-color: #222f6b;">
    <span *ngIf="collapsed" name="arrow-right"><b>></b></span>
    <span *ngIf="!collapsed" name="arrow-left"><b><</b></span>
  </button>
</div>
<div class="sidebar__content">
  <ul>
    <li *ngIf="isAuthenticated"
        class="menu__item"
        [routerLinkActive]="'active'">
      <a routerLink="home">
        <tw-icon name="home"></tw-icon>
        {{ userName }}
      </a>
    </li>
    <tw-menu *ngIf="isAuthenticated && isAdmin"></tw-menu>
  </ul>
</div>
<div class="sidebar__footer">
  <ul>
    <li routerLinkActive="active" *ngIf="!isAuthenticated">
      <a href="javascript:void(0)" (click)="login()">
        Login
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="isAuthenticated">
      <a href="javascript:void(0)" (click)="logout()">
        Logout
      </a>
    </li>
  </ul>
</div>

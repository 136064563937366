<tw-formdef
  [key]="key"
  [viewModel]="viewModel"
  [showSave]="true"
  [showDelete]="!isNew"
  [errors]="errors"
  [useInputForm]="true"
  [form]="form"
  (submitted)="submitted($event)"
  (deleted)="deleted($event)">
</tw-formdef>

<tw-formdef
  [key]="key"
  [viewModel]="viewModel"
  [showSave]="true"
  [saveTitle]="'Register'"
  [showCancel]="false"
  [showDelete]="false"
  [errors]="errors"
  [useInputForm]="true"
  [form]="form"
  (submitted)="submitted($event)">
</tw-formdef>
